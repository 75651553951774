<template>
    <a-row v-if="showTitle">
        <a-col :xxl="8" :lg="10" :md="12" :xs="23" style="padding-left: 0" class="box-event">
            <h3 class="form-font title">Datos específicos por evento</h3>
        </a-col>
    </a-row>
    <a-row :gutter="30" v-for="(item, index) in events" :key="item">
        <a-col :md="24" :xs="24">
            <div class="card-sedes">
                <a-row :gutter="30">
                    <a-col :md="24" :xs="24">
                    <div style="float: right;background: rgb(241 248 253);border-radius: 50px;padding: 5px;width: 25px;height: 25px;">
                        <sdDropdown :action="['click']">
                            <template #overlay >
                              <button class="bt-option-card" @click="editEventRegister(index, item)">
                                <sdFeatherIcons type="edit" style="float:left;" />
                                <span class="title-normal"> Editar evento</span>
                              </button>
                              <button class="bt-option-card" @click='deleteEventRegister(index)'>
                                  <sdFeatherIcons type="trash-2" style="float:left;"/>
                                  <span class="title-normal">Eliminar evento</span>
                              </button>
                            </template>
                            <sdFeatherIcons type="more-vertical" style="color: #3FA7E1;opacity: none !important" />
                        </sdDropdown>
                    </div>
                    </a-col>
                </a-row>
                <a-row :gutter="30" style="border-bottom: 1px solid #CCCCCC;padding-bottom: 1rem;">
                    <a-col :md="24" :xs="24">
                        <span class="title-bold-normal">
                            {{item.title}}
                        </span>
                        <br><br>
                    </a-col>
                    <a-col :md="12" :xs="24">
                        <span class="title-bold-normal">
                            Fecha de inicio del evento:
                        </span>
                        <span class="title-normal">
                           {{item.event_start_date}}
                        </span>
                    </a-col>
                    <a-col :md="12" :xs="24">
                        <span class="title-bold-normal">
                            Fecha de finalización del evento:
                        </span>
                        <span class="title-normal">
                            {{item.event_end_date}}
                        </span>
                    </a-col>
                    <a-col :md="12" :xs="24">
                        <span class="title-bold-normal">
                            Hora de inicio del evento:
                        </span>
                        <span class="title-normal">
                            {{item.event_start_hour}}
                        </span>
                    </a-col>
                    <a-col :md="12" :xs="24">
                        <span class="title-bold-normal">
                            Hora de finalización del evento:
                        </span>
                        <span class="title-normal">
                            {{item.event_end_hour}}
                        </span>
                    </a-col>
                    <a-col :md="12" :xs="24">
                        <span class="title-bold-normal">
                            Fecha de inicio del registro:
                        </span>
                        <span class="title-normal">
                           {{item.start_register_date}}
                        </span>
                    </a-col>
                    <a-col :md="12" :xs="24">
                        <span class="title-bold-normal">
                            Fecha de finalización del registro:
                        </span>
                        <span class="title-normal">
                            {{item.end_register_date}}
                        </span>
                    </a-col>
                </a-row>
                <a-row :gutter="30" style="border-bottom: 1px solid #CCCCCC;padding-bottom: 1rem;padding-top: 1rem;">
                    <a-col :md="12" :xs="24">
                        <span class="title-bold-normal">
                            Sedes del evento:
                        </span>
                        <a-row :gutter="30">
                            <a-col :md="24" v-for="(itemSede, indexSede) in item.headquarters" :key="itemSede">
                                <div v-if="itemSede.type == 'virtual'">
                                    <span class="title-normal">
                                        #{{indexSede+1}}
                                    </span>
                                    <span class="title-normal">
                                        Virtual, {{itemSede.virtual_platform}}, {{itemSede.link}}
                                    </span>
                                </div>
                                <div v-if="itemSede.type == 'face-to-face'">
                                    <span class="title-normal">
                                        #{{indexSede+1}}
                                    </span>
                                    <span class="title-normal">
                                        Presencial, {{itemSede.name}}, {{itemSede.street}} {{itemSede.suburb == "other" ? itemSede.suburb : ""}}, {{itemSede.other_suburb ? itemSede.other_suburb : ""}} {{itemSede.street}}, {{itemSede.municipality}}
                                    </span>
                                </div>
                                <div v-if="itemSede.type == 'both'">
                                    <span class="title-normal">
                                        #{{indexSede+1}}
                                    </span>
                                    <span class="title-normal">
                                        Ambas(virtual y presencial), {{itemSede.virtual_platform}}, {{itemSede.link}}, {{itemSede.name}}, {{itemSede.street}} {{itemSede.suburb == "other" ? itemSede.suburb : ""}}, {{itemSede.other_suburb ? itemSede.other_suburb : ""}} {{itemSede.street}}, {{itemSede.municipality}}
                                    </span>
                                </div>
                            </a-col>
                        </a-row>
                        <br><br>
                    </a-col>
                    <a-col :md="12" :xs="24">
                        <span class="title-bold-normal">
                            Centros de trabajo participantes:
                        </span>
                        <a-row :gutter="30">
                            <div v-if="item.all_work_areas">
                                <span class="title-normal">
                                    - Todos los centros de trabajo pueden participar
                                </span>
                            </div>
                            <div v-else>
                                <div v-if="item.work_center_ids.length">
                                    <a-col :md="24" v-for="(itemWorkCenter) in item.work_center_ids" :key="itemWorkCenter">
                                        <span class="title-normal">
                                            - {{ getNameWorkCenter(itemWorkCenter) }}
                                        </span>
                                    </a-col>
                                </div>
                                <div v-else>
                                    <a-col :md="24" v-for="(itemWorkCenter) in item.work_center_ids" :key="itemWorkCenter">
                                        <span class="title-normal">-</span>
                                    </a-col>
                                </div>
                            </div>
                        </a-row>
                        <br><br>
                    </a-col>
                </a-row>
                 <a-row :gutter="30" style="padding-bottom: 1rem;padding-top: 1rem;">
                    <a-col :md="12" :xs="24">
                        <span class="title-bold-normal">
                            ¿A quién va dirigido el evento?
                        </span>
                        <br>
                        <span class="title-normal">
                            <span class="title-normal">{{item.admin_can_register ? "Personal en centro de trabajo administrativo, " : ""}} {{item.citizen_can_register ? "Ciudadanos, " : ""}} {{item.edu_can_register ? "Estrucutura educativa, " : ""}} {{item.student_can_register ? "Estudiantes, " : ""}}</span>
                        </span>
                    </a-col>
                    <a-col :md="12" :xs="24">
                        <span class="title-bold-normal">
                            ¿Solo el Director puede registrar?
                        </span>
                        <br>
                        <span class="title-normal">
                            {{item.director_register ? "Sí" : "No"}}
                        </span>
                    </a-col>
                    <a-col :md="12" :xs="24">
                        <span class="title-bold-normal">
                            ¿El evento tiene actividades?
                        </span>
                        <br>
                        <span class="title-normal">
                            {{item.activities ? "Sí" : "No"}}
                        </span>
                    </a-col>
                     <a-col :md="12" :xs="24">
                        <span class="title-bold-normal">
                            Cantidad máxima de participantes:
                        </span>
                        <br>
                        <span class="title-normal">
                            {{item.maximum_assistance != null ? item.maximum_assistance : "-"}}
                        </span>
                    </a-col>
                </a-row>
            </div>
        </a-col>
    </a-row>
</template>

<script>
const DetailChildrenEvent = {
    name: 'DetailChildrenEvent',
    components: {

    },
    props: {
        events: [],
        showTitle: Boolean,
        workCenters: [],
        isEdit: Boolean,
    },
    emits:[
      'delete:subEvent',
      'update:subEvent',
    ],
    methods:{
      deleteEventRegister(indexForm){

        this.$emit('delete:subEvent', indexForm);
      },
      editEventRegister(index, sub){

        const subToEdit = JSON.parse(JSON.stringify(sub))
        //subToEdit['registration'] = { admin_can_register: sub.admin_can_register || 0, edu_can_register: sub.edu_can_register || 0, student_can_register: sub.student_can_register || 0, citizen_can_register: sub.citizen_can_register || 0 }
        /*subToEdit['admin_can_register']= sub.admin_can_register || 0
        subToEdit['edu_can_register']=sub.edu_can_register || 0
        subToEdit['student_can_register']= sub.student_can_register || 0
        subToEdit['citizen_can_register']= sub.citizen_can_register || 0*/
        subToEdit['indexToEdit'] = index
        if(this.isEdit && sub.dynamicform){
          subToEdit['director_register']= sub.director_register || 0
          subToEdit['admin_area_register']= sub.admin_area_register || 0
          subToEdit['only_admin_register']= sub.only_admin_register || 0
          subToEdit['activities']= Number.isInteger(sub.activities)
            ? sub.activities==1 ? true : false
            : sub.activities
          subToEdit['questions_json']= JSON.parse(sub.dynamicform.questions_json)
        }
        this.$emit('update:subEvent', subToEdit);
      }
    },
    async setup(props) {

        console.log("evenots", props.events)

        const getNameWorkCenter = (id) => {
            const workCenter = props.workCenters.find((element) => {
                return element.id == id;
            })
            return workCenter?.nombre || ''
        }

        return {
            getNameWorkCenter
        }
    }
}

export default DetailChildrenEvent;
</script>

<style scoped lang="sass">
.card
  background-color: #fff !important
  padding: 1rem
.card-sedes
  background-color: #ffff
  box-shadow: 0px 3px 6px #00000029
  border-radius: 20px
  padding: 1.5rem
  margin: 1rem
.bt-option-card
  width: 100%
  color: #212529
  background: none
  border: none
  padding-left: 15px !important
  padding: 5px
  font-size: 12px
  margin-bottom: 0.5rem
.bt-option-card:hover
  cursor: pointer
  background: #f1efef
.btn-not-border
  border: none !important
.form-font
  font-family: Nutmeg
  &.title
    font-weight: bold
    padding: 0
</style>